@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
body {
  font-family: "Times New Roman", sans-serif;
  font-weight: 400;
}

:root {
  --primaryColor: #74201a;
  --accentColor: #e65151;
  --accentColorLight: #c09694;
}
