.main {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

}
.main::-webkit-scrollbar {
  width: 10px;
}
/* Track */
.main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(187, 187, 187);
  border-radius: 10px;
  text-align: end;

}

/* Handle */
.main::-webkit-scrollbar-thumb {
  transition: all 250ms ease;
  background: var(--accentColor);
  border-radius: 5px;
  text-align: end;

}

.placesList {
  padding: 10px;
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.placeDetails {
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: end;

}
.placeListImage img {
  object-fit: cover;
  object-position: center;
  height: 130px;
  width: 200px;
  
}

/* Handle on hover */
.main::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(var(--accentColor), var(--accentColorLight));
}
.mainText {
  color: var(--accentColor);
  text-align: start;

}
.btnCustom {
  background-color: var(--primaryColor);
  color: #fff;
}
.btnCustom:hover {
  background-color: var(--primaryColor);
  color: #fff;
}
.page a {
  color: var(--accentColor) !important;
  text-align: end;

}

.inp {
  max-width: 250px;
  text-align: end;

}
