.icon {
  max-width: 150px;
  min-width: 100px;
  object-fit: contain;
}

.mainText {
  color: var(--accentColor);
}
.btnCustom {
  background-color: var(--primaryColor);
  color: #fff;
}
.btnCustom:hover {
  background-color: var(--primaryColor);
  color: #fff;
}
.page a {
  color: var(--accentColor) !important;
}
.inp {
  max-width: 500px;
}

.date {
  color: gray;
}

.postImage {
  max-width: 150px;
  min-height: 100px;
  object-fit: contain;
}
