.sidebar {
  width: 100%;
  max-width: 250px; /* Adjust the width as needed */
  background-color: var(--primaryColor);
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
}

.open {
  transform: translateX(0);
}

.logo {
  margin-bottom: 1em;
  color: #fff;
  text-align: center;
}

nav ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

nav li {
  margin-bottom: 0.5rem;
}
nav a {
  display: block;
  padding: 0.5rem 1rem;
  color: white !important;
  text-decoration: none;
  transition: all 100ms ease;
}
/*
}

nav a:active,
nav a:hover,
nav a:focus {
  color: var(--accentColor) !important;
}

nav a:link {
  color: #ffffff;
}

nav a:visited {
  color: #ffffff;
} */

.active {
  font-weight: 700;
  color: var(--accentColor) !important;
}

.sandwichButton {
  margin: 5px 0 0 128px;
  z-index: 200;
  font-size: xx-large;
  color: var(--accentColor);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@media (min-width: 768px) {
  .sidebar {
    width: 250px; /* Adjust the width as needed */
    transform: none;
  }

  .sandwichButton {
    display: none;
  }
}
