.postsList {
  padding: 10px;
  width: 100%;
  max-height: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.postDetails {
  display: flex;
  margin-left: 20px;
  max-width: 230px;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  overflow-wrap: break-word;
}

.postListImage img {
  object-fit: cover;
  object-position: center;
  height: 130px;
  width: 200px;
}
.postButtons {
  max-width: 200px;
}

@media only screen and (max-width: 1000px) {
  .postButtons button,
  .postButtons a {
    font-size: 0.8rem;
  }
  .postListImage img {
    height: 90px;
    width: 120px;
  }

  .postsList {
    font-size: 0.8rem;
  }
  .postDetails {
    width: 180px;
  }
}

@media only screen and (max-width: 460px) {
  .postDetails {
    width: 150px;
  }
  .postButtons button,
  .postButtons a {
    font-size: 0.6rem;
  }
  .postListImage img {
    height: 60px;
    width: 90px;
  }
}
