.mainText {
  color: var(--accentColor);
}
.btnCustom {
  background-color: var(--primaryColor);
  color: #fff;
}
.btnCustom:hover {
  background-color: var(--primaryColor);
  color: #fff;
}
.page a {
  color: var(--accentColor) !important;
}
.inp {
  max-width: 250px;
}
