.thead th {
  background-color: var(--primaryColor) !important;
}
.customBtn {
  background-color: var(--accentColor);
}

@media only screen and (max-width: 1100px) {
  .table td,
  .table th,
  .table button {
    font-size: 0.7rem;
    padding: 0.5rem;
  }
}
@media only screen and (max-width: 950px) {
  .table td,
  .table th {
    max-width: 10px;
    overflow-wrap: break-word;
    margin: 0px 2px;
    /* width: 100%; */
  }
}
@media only screen and (max-width: 768px) {
  .table td,
  .table th,
  .table button {
    font-size: 0.5rem;
    justify-items: center;
  }
  .table td,
  .table th {
    max-width: 10px;
    overflow-wrap: break-word;
    margin: 0px 2px;
    /* width: 100%; */
  }

  .table td:before {
    content: attr(data-label);
    font-weight: bold;
    display: block;
    margin-bottom: 0.5rem;
  }
}
